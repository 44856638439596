import React, { useState } from "react";
import navbarLogo from "../../Images/HomePageImages/henneberry_logo1.png";
import "../../Style/Navbar.css";
import navbarwhtsp from "../../Images/HomePageImages/narbar_whatsapp.png";
import { FaAngleDown } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { IoClose } from "react-icons/io5";
const NavBar = ({ getUserAuthenticationRedux }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="desktop_Navbar">
        <div className="container-xxl p-0">
          <div className="row d-flex justify-content-between">
            <div className="col-xl-2 col-md-3">
              <img src={navbarLogo} alt="img" onClick={() => navigate("/")} />
            </div>
            <div className="col-xl-6 col-md-8">
              <ul className="d-flex justify-content-between align-items-center">
                <li onClick={() => navigate("/")}>Home</li>
                <li onClick={() => navigate("/about")}>About Us</li>
                <li onClick={() => navigate("/blog")}>Blog</li>

                <li onClick={() => navigate("/contact")}>Contact Us</li>
                <li onClick={() => navigate("/tenancy-application-form")}>
                  Tenancy Application
                </li>
                {/* {getUserAuthenticationRedux ? (
                  <li onClick={() => navigate("/profile")}>Profile</li>
                ) : (
                  <li onClick={() => navigate("/login")}> Login</li>
                )} */}
                <li className="narbar_whtsaap">
                  <img src={navbarwhtsp} alt="img" />
                  <NavLink
                    to="https://wa.me/61456761421"
                    className="text-decoration-none text-white"
                    target="blank"
                  >
                    WhatsApp
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* mobile_navbar -------------------------------------------------- */}
      <div className="mobile_navbar">
        <div className="row d-flex justify-content-between ">
          <div className="col-sm-2 col-4">
            <div className="logo">
              <img src={navbarLogo} alt="img" onClick={() => navigate("/")} />
            </div>
          </div>
          <div className="col-sm-6 col-8">
            <div className="toogle d-flex justify-content-end">
              <FaBars data-bs-toggle="offcanvas" data-bs-target="#demo" />
            </div>
            <div className="offcanvas offcanvas-end" id="demo">
              <div className="offcanvas-header">
                <div className="logo">
                  <img src={navbarLogo} alt="img-alt" />
                </div>
                <IoClose data-bs-dismiss="offcanvas" className="btn-close" />
              </div>
              <ul>
                <li onClick={() => navigate("/")}>Home</li>
                <li onClick={() => navigate("/about")}>About Us</li>
                <li onClick={() => navigate("/blog")}>Blog</li>

                <li onClick={() => navigate("/contact")}>Contact Us</li>
                <li onClick={() => navigate("/tenancy-application-form")}>
                  Tenancy Application
                </li>
                {/* {getUserAuthenticationRedux ? (
                  <li onClick={() => navigate("/profile")}>Profile</li>
                ) : (
                  <li onClick={() => navigate("/login")}> Login</li>
                )} */}

                <li className="narbar_whtsaap">
                  <img src={navbarwhtsp} alt="img" />
                  <NavLink
                    href="https://wa.me/61456761421"
                    className="text-decoration-none text-black"
                    target="_blank"
                  >
                    WhatsApp
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setUserAuthenticationRedux: (parameter) => {
    //   dispatch(setUserAuthentication(parameter));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
