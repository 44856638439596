import React from "react";
import "../../Style/OurSupport.css";
import dh from "../../Images/HomePageImages/DH.png";
import { useNavigate } from "react-router-dom";
import support1 from '../../Images/HomePageImages/support1.png'
import support2 from '../../Images/HomePageImages/support2.jpg'

function OurSupport() {
  const navigate = useNavigate();
  return (
    <div className="ourSupport">
      <div className="container">
        <h3 className="text-center">Who We Support</h3>
        <div className="logo-container row">

          <div className="col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div>

          {/* <div className="col-lg-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}

          <div className=" col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://mnmllife.co/pages/the-science"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support2} alt="Brand 1" height={100} width={100}/>
              </a>
            </div>
          </div>

          <div className=" col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.georgecapital.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={support1} alt="Brand 1" />
              </a>
            </div>
          </div>

          <div className="col-lg-3 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div>
{/* 
          <div className="col-lg-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <img src={dh} alt="Brand 1" />
            </div>
          </div> */}



          {/* <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div>
          <div className="col-md-2 mt-2 mt-lg-0">
            <div className="logo-item">
              <a
                href="https://www.instagram.com/dihenneberry/#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dh} alt="Brand 1" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OurSupport;
