import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import "../../Style/PropertyDetail/AboutProperty.css";
import Code from "../../Code";
import { ContactusApi } from "../../API/ContactUsApi";
import { ErrorToaster } from "../../Toster";
import areaimg from "../../Images/DetailsPage/area_img.png";
import bedroom from "../../Images/DetailsPage/bedroom.png";
import bathroom from "../../Images/DetailsPage/bathroom.png";
import garage from "../../Images/DetailsPage/garage.png";
import livingroom from "../../Images/DetailsPage/livingroom.png";
import Priceimg from "../../Images/DetailsPage/price_img.png";
import secondconfigurationimg from "../../Images/DetailsPage/second_configuration_img.png";
import floorimg from "../../Images/DetailsPage/floors_img.png";
import Propertyimg from "../../Images/DetailsPage/property_img.png";
import { FaRupeeSign } from "react-icons/fa";

const AboutProperty = ({ data, about }) => {
  const configurations = data?.configurations;
  const area = data?.area;
  const [selectCountryCode, setSelctCountryCode] = useState("+61");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      selectCountryCode === "" ||
      email === "" ||
      phone === ""
    ) {
      ErrorToaster("All field is Required");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Valid Email");
    } else if (phone.length < 9 || phone.length > 11) {
      ErrorToaster("Number is less than 12 and greater than 11");
    } else {
      let fromdata = {
        first_name: name,
        email: email,
        country_code: selectCountryCode,
        contact_number: phone,
        message: subject,
      };
      ContactusApi(fromdata);
      setEmail("");
      setName("");
      setSubject("");
      setPhone("");
    }
  };

  function rtrim(str, charToRemove = " ") {
    let regex = new RegExp(charToRemove + "+$", "g");
    return str.replace(regex, "");
  }

  const propertyDetails = [
    {
      icon: bedroom,
      label: "Bedrooms",
      value:
        configurations && configurations.number_of_bedrooms
          ? configurations.number_of_bedrooms
          : "",
    },
    {
      icon: bathroom,
      label: "Bathrooms",
      value:
        configurations && configurations.number_of_bathrooms
          ? configurations.number_of_bathrooms
          : "",
    },
    {
      icon: livingroom,
      label: "Living area",
      value:
        configurations && configurations.living_areas
          ? configurations.living_areas
          : "",
    },
    {
      icon: garage,
      label: "Garage",
      value:
        configurations && configurations.garage_spaces
          ? configurations.garage_spaces
          : "",
    },
    {
      icon: Priceimg,
      label: "Price",
      value: data?.price
        ? `$${rtrim(data.price, ".0")}`
        : data?.price_in_text
        ? data?.price_in_text
        : "",
    },
    {
      icon: floorimg,
      label: "Total Floors",
      value: configurations?.total_floors
        ? `${configurations.total_floors}`
        : null,
    },
    {
      icon: Propertyimg,
      label: "Property Age (in Years)",
      value: configurations?.property_age
        ? `${configurations.property_age}`
        : null,
    },
    {
      icon: areaimg,
      label: "Area",
      value: area?.land_size
        ? `${parseInt(area.land_size)} ${area.land_size_unit}`
        : null,
    },
  ];

  const filteredDetails = propertyDetails.filter((detail) => detail.value);
  return (
    <>
      <div className="aboutProperty">
        <div className="container-xxl p-0">
          <div className="row">
            <div className="col-lg-6">
              {data?.nearByAddress?.nearby_address_1 ||
                (data?.nearByAddress?.nearby_address_2 && (
                  <div className="leftSec">
                    <div className="row top d-flex justify-content-between align-items-baseline">
                      {filteredDetails.map((detail, index) => (
                        <React.Fragment key={index}>
                          <div className="col-md-6">
                            <div className="d-flex flex-column">
                              <div className="d-flex">
                                <img src={detail.icon} alt="img" />
                                <div className=" ms-3">
                                  <span className="opacity-75">
                                    {detail.label}
                                  </span>
                                  <p className="plot_area">{detail.value}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {(index + 1) % 2 === 0 &&
                            index !== filteredDetails.length - 1 && (
                              <hr className="bottom_line" />
                            )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              {data?.nearByAddress?.nearby_address_1 ||
                (data?.nearByAddress?.nearby_address_2 && (
                  <div className="contact_form">
                    <h6>Send enquiry to dealer</h6>
                    <form onSubmit={handleSubmit}>
                      <lable for="name">Name*</lable>
                      <input
                        type="text"
                        className="mb-2"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <lable for="name">Email*</lable>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="mt-3 d-flex flex-wrap align-items-center gap-3">
                        <input
                          className="custom-select"
                          value={selectCountryCode}
                          style={{ width: "20%", height: "33px" }}
                        ></input>
                        <input
                          type="number"
                          placeholder="Phone number"
                          style={{ width: "70%" }}
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="mb-3 mt-4 ">
                        <textarea
                          type="text"
                          className="form-control"
                          id="your-subject"
                          name="your-subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <button className="semdEmail" type="submit">
                        Send email
                      </button>
                    </form>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutProperty;
