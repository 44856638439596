import React, { useEffect, useState } from "react";
import "../../Style/PropertyDetail/FAQ.css";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FAQAPI } from "../../API/CommanApi";

const PropertyFAQ = () => {
  const [faqdata, setFaqdata] = useState([]);

  useEffect(() => {
    FAQAPI((callback) => {
      setFaqdata(callback?.data?.faqs);
    });
  }, []);

  return (
    <>
      <div id="faq" className="faq">
        <div className="container-xxl p-0">
          <div>
            <h4>Property FAQs</h4>
          </div>
          <div className="faq-list">
            <ul>
              {faqdata
                ? faqdata.map((iteam, i) => {
                    const { id, question, answer } = iteam;
                    const panelId = `faq-list-${id}`;
                    return (
                      <li key={i}>
                        <i
                          className="fa fa-question-circle icon-help"
                          aria-hidden="true"
                        ></i>
                        <a
                          data-bs-toggle="collapse"
                          className="collapsed"
                          data-bs-target={`#${panelId}`}
                        >
                          {question}
                          <IoIosArrowDown
                            className="icon-show"
                            aria-hidden="false"
                          />
                          <IoIosArrowUp className="icon-close" />
                        </a>
                        <div
                          id={panelId}
                          className="collapse"
                          data-bs-parent=".faq-list"
                        >
                          <p>{answer}</p>
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyFAQ;
